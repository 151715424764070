import { useEffect, useState, React } from "react";
import "./Hero_2.css";
import banner_img from "../../../../Logos/banner2.png";
import hero_mobile from "../../../../Logos/hero_mobile.png";
import client from "../../../../Logos/client.svg";
import trust from "../../../../Logos/trust.svg";
import state from "../../../../Logos/state.svg";

function Hero2() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);

  useEffect(() => {
    const mobileQuery = window.matchMedia("(max-width: 767px)");
    const tabletQuery = window.matchMedia(
      "(min-width: 768px) and (max-width: 1023px)"
    );
    const laptopQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQuery = () => {
      setIsMobile(mobileQuery.matches);
      setIsTablet(tabletQuery.matches);
      setIsLaptop(laptopQuery.matches);
    };
    handleMediaQuery(); // Call the function initially to set the initi
    mobileQuery.addListener(handleMediaQuery);
    tabletQuery.addListener(handleMediaQuery);
    laptopQuery.addListener(handleMediaQuery);
    return () => {
      mobileQuery.removeListener(handleMediaQuery);
      tabletQuery.removeListener(handleMediaQuery);
      laptopQuery.removeListener(handleMediaQuery);
    };
  }, []);
  return (
    <div>
      {isLaptop && (
        <div className="container-hero-2">
          <img className="banner-img-hero-2" src={banner_img} alt=""></img>
          <div className="sales-info-hero-2">
            <div className="image-container-with-text">
              <img className="image-01-hero-2" alt="" src={trust}></img>
              <span className="trust-hero-2-text">100%</span>{" "}
              <span className="trust-hero-3-text">TRUST</span>
            </div>{" "}
            <div className="image-container-with-text">
              <img className="image-02-hero-2" alt="" src={state}></img>
              <span className="trust-hero-2-text">22</span>{" "}
              <span className="trust-hero-3-text">STATES</span>
            </div>{" "}
            <div className="image-container-with-text">
              <img className="image-03-hero-2" alt="" src={client}></img>
              <span className="trust-hero-2-text">2000+</span>{" "}
              <span className="trust-hero-3-text">CLIENTS</span>
            </div>
          </div>
        </div>
      )}

      {isTablet && (
        <div className="container-hero-2">
          <img className="banner-img-hero-2" src={banner_img} alt=""></img>
          <div className="sales-info-hero-2">
            <div className="image-container-with-text">
              <img className="image-01-hero-2" alt="" src={trust}></img>
              <span className="trust-hero-2-text">100%</span>{" "}
              <span className="trust-hero-3-text">TRUST</span>
            </div>{" "}
            <div className="image-container-with-text">
              <img className="image-02-hero-2" alt="" src={state}></img>
              <span className="trust-hero-2-text">22</span>{" "}
              <span className="trust-hero-3-text">STATES</span>
            </div>{" "}
            <div className="image-container-with-text">
              <img className="image-03-hero-2" alt="" src={client}></img>
              <span className="trust-hero-2-text">2000+</span>{" "}
              <span className="trust-hero-3-text">CLIENTS</span>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="container-hero-2">
          <img className="hero-mobile-hero-2" src={hero_mobile} alt=""></img>
          <div className="sales-info-hero-2">
            <div className="image-container-with-text">
              <img className="image-01-hero-2" alt="" src={trust}></img>
              <span className="trust-hero-2-text">100%</span>{" "}
              <span className="trust-hero-3-text">TRUST</span>
            </div>{" "}
            <div className="image-container-with-text">
              <img className="image-02-hero-2" alt="" src={state}></img>
              <span className="trust-hero-2-text">22</span>{" "}
              <span className="trust-hero-3-text">STATES</span>
            </div>{" "}
            <div className="image-container-with-text">
              <img className="image-03-hero-2" alt="" src={client}></img>
              <span className="trust-hero-2-text">2000+</span>{" "}
              <span className="trust-hero-3-text">CLIENTS</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hero2;
