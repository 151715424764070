import Homepage from "./Pages/English/Homepage/Homepage";
import "./App.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Productview from "./Pages/English/Homepage/Productview/Productview";
import { AppContext } from "./Pages/English/Homepage/Context/Context";
function App() {
  const [productdetails, setproductdetails] = useState([]);
  return (
    <AppContext.Provider value={{ productdetails, setproductdetails }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage></Homepage>}></Route>
          <Route
            path="/productview/:id"
            element={<Productview></Productview>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
