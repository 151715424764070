import React, { useEffect, useContext } from "react";
import "./Productview.css";
import Header from "../Header/Header";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper React components
import "swiper/css";
import { AppContext } from "../Context/Context";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import axios from "axios";
import emailogo from "../../../../Logos/Gmail.png";
import { useParams } from "react-router-dom";
/* import { FreeMode, Pagination } from "swiper/modules"; */
// If you use Swiper 6.0.0 or higher
import "swiper/css/effect-coverflow";

const params = {
  spaceBetween: 30,
  slidesPerView: 1,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

function Productview() {
  const phoneNumber = "+91 9994551261";
  const emailSubject = "NEW ENQUIRY";
  const emailAddress = "balloon.tradingindia@gmail.com";
  const Callseller = () => {
    const telUri = `tel:${phoneNumber}`;
    window.open(telUri, "_blank"); // Opens the phone dialer with the specified phone number
  };
  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      emailSubject
    )}`;
  };
  const { id } = useParams();
  /*   const [products, setProducts] = useState([]); */
  const { productdetails, setproductdetails } = useContext(AppContext);

  useEffect(() => {
    axios
      .get(
        `https://ceethoo-api.onrender.com/api/getallproducts?limit=10&page=1`
      )
      .then((response) => {
        /*  setProducts(response.data); */
        const image = new Image();
        image.src = response.data[0].image1;
      });
    axios
      .get(`https://ceethoo-api.onrender.com/productview/${id}`)
      .then((response) => {
        setproductdetails(response.data);
        console.log(response.data.productname);
      });
  }, [id, setproductdetails]); // Add id as a dependency

  return (
    <div className="productview-main-container">
      {" "}
      <Header></Header>
      <div className="content-productview">
        <div className="slider-content-productview">
          <Swiper {...params}>
            <SwiperSlide>
              {" "}
              <img
                src={productdetails.image1}
                alt=""
                style={{
                  width: "100%",
                  height: "350px",
                  objectFit: "cover",
                  backgroundColor: "blue",
                }}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img
                src="https://cdn.pixabay.com/photo/2019/03/03/08/25/rabbit-4031334_1280.png"
                alt=""
                style={{
                  width: "100%",
                  height: "350px",
                  objectFit: "cover",
                  backgroundColor: "blue",
                }}
              ></img>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img
                src="https://cdn.pixabay.com/photo/2015/10/29/14/39/web-1012469_1280.jpg"
                alt=""
                style={{
                  width: "100%",
                  height: "350px",
                  objectFit: "cover",
                  backgroundColor: "blue",
                }}
              ></img>
            </SwiperSlide>
          </Swiper>{" "}
        </div>
        <div className="inside-content-productview">
          <h1 className="productname-title-productview">
            {productdetails.productname}
          </h1>{" "}
          <span className="price-productview">
            Starting at${productdetails.orginalprice}
          </span>{" "}
          <span className="category-productview">
            {productdetails.productdesc}
          </span>
          <ul className="buller-content-productview">
            <li>{productdetails.highlight_1}</li>{" "}
            <li>{productdetails.highlight_2}</li>{" "}
            <li>{productdetails.highlight_3}</li>{" "}
            <li>{productdetails.highlight_4}</li>
          </ul>
        </div>
      </div>
      <div className="button-container">
        <button className="contactseller-button" onClick={Callseller}>
          Contact Seller
        </button>
        <button onClick={handleEmailClick} className="sendemail-button">
          Send Email
        </button>
      </div>
      <div className="space-productview"></div>
      <div className="contact-info-productview">
        <span>Contact us</span>
        <div className="gmail-container-productview">
          <img className="gmail-logo" src={emailogo} alt=""></img>
          <span>Balloon.tradingindia@gmail.com</span>
        </div>
        <span>India: +91-9994551261</span>
        <span>UAE: +971-524673077</span>
      </div>
    </div>
  );
}

export default Productview;
