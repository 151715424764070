import React from "react";
import "./Sub.css";
import med from "../../../../Logos/Medicine.png";
import rices from "../../../../Logos/rices.png";
import Pules from "../../../../Logos/Pulues.png";
import Medical_equipment from "../../../../Logos/Medical_eqipment.png";
import Electronics from "../../../../Logos/electronics.png";
import otheritems from "../../../../Logos/other.png";
const Sub = () => {
  return (
    <div className="container-sub">
      <div className="border-div">
        <div className="image-container-main">
          <div className="image-container">
            {" "}
            <img className="med-image" src={med} alt=""></img>{" "}
          </div>{" "}
          <span>Medicines</span>
        </div>
        <div className="image-container-main">
          <div className="image-container">
            {" "}
            <img className="rice-image" src={rices} alt=""></img>{" "}
          </div>{" "}
          <span>Rices</span>
        </div>
        <div className="image-container-main">
          <div className="image-container">
            {" "}
            <img className="med-image" src={Pules} alt=""></img>{" "}
          </div>{" "}
          <span>Pulses</span>
        </div>
        <div className="image-container-main">
          <div className="image-container">
            {" "}
            <img
              className="med-image"
              src={Medical_equipment}
              alt=""
            ></img>{" "}
          </div>{" "}
          <span>Medical Equip</span>
        </div>
        <div className="image-container-main">
          <div className="image-container">
            {" "}
            <img className="med-image" src={Electronics} alt=""></img>{" "}
          </div>{" "}
          <span>Electronics</span>
        </div>
        <div className="image-container-main">
          <div className="image-container">
            {" "}
            <img className="med-image" src={otheritems} alt=""></img>{" "}
          </div>{" "}
          <span>Other</span>
        </div>
      </div>
    </div>
  );
};

export default Sub;
