import React from "react";
import "./About.css";
import doctor from "../../../../Logos/doctor.png";
import phone from "../../../../Logos/Phonecall.svg";
import Mail from "../../../../Logos/Gmail.png";
import Location from "../../../../Logos/whatsapp.svg";

function About() {
  const emailAddress = "balloon.tradingindia@gmail.com";
  const emailSubject = "NEW ENQUIRY";
  const phoneNumber = "+91 9994551261";
  const mapLink = "https://wa.me/+971524673077"; // Replace with your actual map link
  const handleOpenMap = () => {
    window.location.href = mapLink;
  };
  const Callseller = () => {
    const telUri = `tel:${phoneNumber}`;
    window.open(telUri, "_blank"); // Opens the phone dialer with the specified phone number
  };
  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}?subject=${encodeURIComponent(
      emailSubject
    )}`;
  };
  return (
    <div>
      <div className="contact-info-about">
        <div className="phone-call-container-about-us">
          <img className="phonecall-logo-about-us" src={phone} alt=""></img>
          <button onClick={Callseller} className="button-call-about-us">
            Call Now
          </button>
        </div>
        <div className="phone-call-container-about-us">
          <img className="phonecall-logo-about-us" src={Mail} alt=""></img>
          <button onClick={handleEmailClick} className="button-email-about-us">
            Enquiry Now
          </button>
        </div>
        <div className="phone-call-container-about-us">
          <img className="map-logo-about-us" src={Location} alt=""></img>
          <button onClick={handleOpenMap} className="button-map-about-us">
            Chat Now
          </button>
        </div>
      </div>
      <div className="container-about-us">
        <div className="image-container-about-us">
          <img className="image-about-us" src={doctor} alt=""></img>
        </div>{" "}
        <div className="content-container-about-us">
          <span className="text-content-container">
            Meyar Pharmaceuticals Pvt. Ltd | Pharma Franchise Company. Meyar
            Pharmaceuticals is specialized in the distribution of a diverse
            range of medicines, healthcare products that meet the growing needs
            of patients across India, middle east and Africa, We are a leading
            provider of high-quality pharmaceutical products and healthcare
            solutions. Our company is the Best Pharma Franchise Company and is
            ISO, WHO-GMP, and GLP certified. We have committed to delivering
            safe, effective, and affordable healthcare solutions to improve the
            health and well-being of people across the country. Our products
            have manufactured under strict quality control measures and adhere
            to international standards, ensuring the highest level of safety and
            efficacy. Our company deals in 500 products. At our Medicine
            Franchise Company, we have dedicated to providing superior customer
            service and support to our clients, with a focus on building lasting
            partnerships and delivering value-added solutions. WHY CHOOSE US?
            Meyar Pharmaceuticals Private Limited Meyar Pharmaceuticals access
            to high-quality pharmaceutical products that are in high demand. Our
            Pharma Franchise Company is offering the best range of pharma
            products. We have DCGI approved product range. We also export
            pharmaceutical products under the name of clients brand. • WHO-GMP
            Certified Company in India • Access to a wide range of products •
            On-time delivery of the products • High Quality of the products. •
            Innovative research and development capabilities Range of the pharma
            products with the best quality. Tablets We are offering a wide range
            of tablets in various therapeutic forms Cardiac Diabetic We provide
            the best quality Cardiac Diabetic for distribution across India.
            Capsules Meyr Pharmaceuticals is one of the best to offer the
            capsules range. Syrups Our syrup range is the most effective and
            best in quality. Injectables We never compromise with the quality of
            our Injectables range. Eye & Ear Drops Grab the best opportunity in
            the Eye & Ear Drops and get affordable products. Nasal Sprays &
            Drops Our top quality Nasal Drops & Sprays range is highly
            recommended by doctors and pharma professionals. Derma Products We
            offer the most effective derma range which is best for the skin.
          </span>
        </div>
      </div>
    </div>
  );
}

export default About;
