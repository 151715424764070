import Header from "./Header/Header";
import "./Homepage.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Hero2 from "./Hero_2/Hero_2";
import About from "./Aboutus/About";
import Sub from "./Catergories/Sub";

function Homepage() {
  const phoneNumber = "+91 9994551261";
  const [imageLoaded] = useState(true);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const Productview = (obj_id) => {
    navigate(`/productview/${obj_id}`);
  };

  const Callseller = () => {
    const telUri = `tel:${phoneNumber}`;
    window.open(telUri, "_blank"); // Opens the phone dialer with the specified phone number
  };
  useEffect(() => {
    axios
      .get(
        `https://meyar-backend.onrender.com/api/getallproducts?limit=10&page=1`
      )
      .then((response) => {
        setProducts(response.data);
        const image = new Image();
        image.src = response.data[0].image1;
      });
  });

  return imageLoaded ? (
    <div>
      <Header></Header>
      <Hero2></Hero2>
      <Sub></Sub>
      <About></About>
      <div className="space"></div>
      <div className="product-list">
        {products.map((item, index) => (
          <div key={index} className="card">
            <div className="img-bg">
              <img
                onClick={() => Productview(item._id)}
                className="card-image"
                src={item.image1}
                alt="Server Error"
              />
            </div>
            <div className="card-content">
              <h1 className="card-title">{item.productname}</h1>{" "}
              <h1 className="card-text">Starting Rs.1542</h1>{" "}
              <button onClick={Callseller} className="card-button">
                Call Seller
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default Homepage;
